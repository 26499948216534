
import { Vue, Component, Watch } from "vue-property-decorator";
import { InventarioService } from "@/core/services/almoxarifado";
import { Inventario } from "@/core/models/almoxarifado";
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";

@Component
export default class ListaInventario extends mixins(Vue, ListPage) {
  
  item = new Inventario();
  service = new InventarioService();
   
  loading: boolean = false;
  dialogCadastro: boolean = false;
  dialogAtender: boolean = false;
  totalLista: number = 0;

  titulo: string = "Ajuste de Estoque";
  subTitulo: string = "Ajustes de Estoques cadastrados no Sistema";
   
 
  options: any = {
    itemsPerPage: 15,
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Data", value: "data", type:'text' },
    { text: "Almoxarifado", value: "almoxarifado.nome" },
    { text: "Executado", value: "executada", type:'text' },
  ];

  
  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Estoque', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, '','Almoxarifado, Itens').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
       (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        })
    .finally(() => (this.loading = false));
  }

  Excluir(item: Inventario) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    });
  } 

  Editar(item: Inventario) {
    this.service
      .ObterPorId(item.id, "Almoxarifado, Itens, Itens.Produto")
      .then((res) => {
        this.item = res.data;
        this.dialogCadastro = true;
      });
  }

  Novo() {
    this.item = new Inventario();
    this.dialogCadastro = true;
  }

  Atender(item: Inventario) {
    this.service
      .ObterPorId(item.id, "Almoxarifado, Itens, Itens.Produto")
      .then((res) => {
        this.item = res.data;
        this.dialogAtender = true;
      });
  }
  
  mounted(){
    //this.Atualizar();
  }
}
